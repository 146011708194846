var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "wrap"
  }, [_vm.itemData.state == 1 ? _c('div', {
    staticClass: "state-div state-div-c"
  }, [_vm._m(0)]) : _vm._e(), _vm.itemData.state == 2 ? _c('div', {
    staticClass: "state-div state-div-c"
  }, [_vm._m(1), _vm.itemData.expressCode ? _c('div', {
    staticClass: "logistics-box"
  }, [_c('img', {
    staticClass: "icon",
    attrs: {
      "src": "https://qiniu.touchdot.top/code-cdn/hongrun-gifttwo/icon/icon-3.png"
    }
  }), _c('div', {
    staticClass: "info"
  }, [_c('div', {
    staticClass: "state"
  }, [_vm._v(_vm._s(_vm.itemData.expressCompany))]), _c('div', {
    staticClass: "detail"
  }, [_vm._v("运单号：" + _vm._s(_vm.itemData.expressCode))]), _c('div', {
    directives: [{
      name: "clipboard",
      rawName: "v-clipboard:copy",
      value: _vm.itemData.expressCode,
      expression: "itemData.expressCode",
      arg: "copy"
    }, {
      name: "clipboard",
      rawName: "v-clipboard:success",
      value: _vm.copySuccess,
      expression: "copySuccess",
      arg: "success"
    }],
    staticClass: "see"
  }, [_vm._v("复制单号")])])]) : _c('view', {
    staticClass: "logistics-box"
  }, [_c('image', {
    staticClass: "icon",
    attrs: {
      "src": "https://qiniu.touchdot.top/code-cdn/hongrun-gifttwo/icon/icon-3.png"
    }
  }), _c('view', {
    staticClass: "info"
  }, [_c('view', {
    staticClass: "state"
  }, [_vm._v("下单成功")]), _c('view', {
    staticClass: "detail"
  }, [_vm._v("商品正在出库")])])])]) : _vm._e(), _vm.itemData.state == 3 ? _c('div', {
    staticClass: "state-div state-div-lv"
  }, [_vm._m(2)]) : _vm._e(), _c('div', {
    staticClass: "address-div"
  }, [_c('img', {
    staticClass: "icon",
    attrs: {
      "src": "https://qiniu.touchdot.top/code-cdn/hongrun-gifttwo/icon/icon-18.png"
    }
  }), _c('div', {
    staticClass: "info"
  }, [_c('div', {
    staticClass: "name-and-phone"
  }, [_c('div', {
    staticClass: "name"
  }, [_vm._v(_vm._s(_vm.itemData.username))]), _c('div', {
    staticClass: "phone"
  }, [_vm._v(_vm._s(_vm.itemData.phone))])]), _c('div', {
    staticClass: "address"
  }, [_vm._v(" " + _vm._s(_vm.itemData.address) + " ")])])]), _c('div', {
    staticClass: "info-box"
  }, [_c('div', {
    staticClass: "order-good-box"
  }, _vm._l(_vm.itemData.goods, function (item) {
    return _c('div', {
      key: item.id,
      staticClass: "good",
      on: {
        "click": function ($event) {
          return _vm.goDetail(item.good_id);
        }
      }
    }, [_c('div', {
      staticClass: "cover"
    }, [_c('van-image', {
      attrs: {
        "width": "100%",
        "height": "100%",
        "fit": "cover",
        "src": item.goodCover
      }
    })], 1), _c('div', {
      staticClass: "info"
    }, [_c('div', {
      staticClass: "name"
    }, [_vm._v(_vm._s(item.goodName))])])]);
  }), 0), _vm.orderType == 1 ? _c('div', {
    staticClass: "total-box"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("订单总价：")]), _c('div', {
    staticClass: "total"
  }, [_vm._v(_vm._s(_vm.itemData.integral ? `${_vm.itemData.integral}积分` : '') + _vm._s(_vm.itemData.integral && _vm.itemData.price ? '+' : '') + _vm._s(_vm.itemData.price ? `${_vm.itemData.price}元` : ''))])]) : _vm._e()]), _c('div', {
    staticClass: "order-info"
  }, [_c('div', {
    staticClass: "li"
  }, [_vm._m(3), _c('div', {
    staticClass: "right-box"
  }, [_c('div', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.itemData.orderNo))])])]), _c('div', {
    staticClass: "li"
  }, [_vm._m(4), _c('div', {
    staticClass: "right-box"
  }, [_c('div', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.itemData.createTime))])])]), _vm.itemData.payTime ? _c('div', {
    staticClass: "li"
  }, [_vm._m(5), _c('div', {
    staticClass: "right-box"
  }, [_c('div', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.itemData.payTime))])])]) : _vm._e()]), false ? _c('foot-bar-box-comp', [_c('div', {
    staticClass: "button-box"
  }, [_vm.itemData.state == 0 ? [_c('button', {
    staticClass: "but",
    attrs: {
      "hover-class": "hoverview"
    }
  }, [_vm._v("取消订单")]), _c('button', {
    staticClass: "but fill"
  }, [_vm._v("去支付")])] : _vm.itemData.state == 2 ? [_c('button', {
    staticClass: "but cut"
  }, [_vm._v("确定收货")])] : _vm.itemData.state == 3 ? [_c('button', {
    staticClass: "but"
  }, [_vm._v("评价")]), _c('button', {
    staticClass: "but"
  }, [_vm._v("退货/换货")]), _c('button', {
    staticClass: "but cut"
  }, [_vm._v("再次购买")])] : _vm.itemData.state == 4 ? void 0 : _vm.itemData.state == 10 ? [_c('button', {
    staticClass: "but",
    attrs: {
      "hover-class": "hoverview"
    }
  }, [_vm._v("取消订单")]), _c('button', {
    staticClass: "but cut"
  }, [_vm._v("立即分享")])] : _vm._e()], 2)]) : _vm._e()], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "state-box"
  }, [_c('div', {
    staticClass: "info"
  }, [_c('div', {
    staticClass: "state"
  }, [_c('img', {
    staticClass: "si",
    attrs: {
      "src": "https://qiniu.touchdot.top/code-cdn/hongrun-gifttwo/icon/state-icon-1.png"
    }
  }), _vm._v(" 待发货 ")]), _c('div', {
    staticClass: "time"
  }, [_vm._v("正在等待工作人员发货")])]), _c('img', {
    staticClass: "icon",
    attrs: {
      "src": "https://qiniu.touchdot.top/code-cdn/hongrun-gifttwo/icon/state-1.png"
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "state-box"
  }, [_c('div', {
    staticClass: "info"
  }, [_c('div', {
    staticClass: "state"
  }, [_c('img', {
    staticClass: "si",
    attrs: {
      "src": "https://qiniu.touchdot.top/code-cdn/hongrun-gifttwo/icon/state-icon-2.png"
    }
  }), _vm._v(" 待收货 ")]), _c('div', {
    staticClass: "time"
  }, [_vm._v("您的宝贝正在飞奔过来的路上")])]), _c('img', {
    staticClass: "icon",
    attrs: {
      "src": "https://qiniu.touchdot.top/code-cdn/hongrun-gifttwo/icon/state-2.png"
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "state-box"
  }, [_c('div', {
    staticClass: "info"
  }, [_c('div', {
    staticClass: "state"
  }, [_c('img', {
    staticClass: "si",
    attrs: {
      "src": "https://qiniu.touchdot.top/code-cdn/hongrun-gifttwo/icon/state-icon-3.png"
    }
  }), _vm._v(" 已完成 ")]), _c('div', {
    staticClass: "time"
  }, [_vm._v("宝贝已签收，感谢您的使用！")])]), _c('img', {
    staticClass: "icon",
    attrs: {
      "src": "https://qiniu.touchdot.top/code-cdn/hongrun-gifttwo/icon/state-3.png"
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "left-box"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("订单编号")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "left-box"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("下单时间")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "left-box"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("付款时间")])]);
}];
export { render, staticRenderFns };