export default {
  name: 'shop',
  components: {},
  data() {
    return {
      orderType: null,
      itemData: {
        state: 0
      }
    };
  },
  async created() {},
  async mounted() {
    this.getDetail();
  },
  methods: {
    copySuccess() {
      this.$toast({
        message: '复制成功'
      });
    },
    async getDetail() {
      let {
        id,
        orderType
      } = this.$route.params;
      this.orderType = orderType;
      let url = (orderType == 1 ? 'app/api/shop/order/' : '/app/api/my/order/') + id;
      console.log(url);
      let result = await this.$request({
        method: 'get',
        url: url
      });
      console.log(result);
      if (result.code == 0) {
        this.itemData = result.data;
      }
    }
  }
};